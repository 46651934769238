/**
 * See `transformTcfAdTags` for more information.
 */ function transformTcfSrc(oldSrc, tcfStringForVendors, gvl) {
    let src = oldSrc.replace(/(gdpr=)(\${GDPR})/gm, `$1${tcfStringForVendors ? "1" : "0"}`);
    if (tcfStringForVendors) {
        // We do have a TCF string, cause the GDPR applies here
        src = src.replace(/(gdpr_consent=)(\${GDPR_CONSENT_(\d+)})/gm, (m, parameter, previousValue, vendor)=>{
            // Get vendor from GVL
            const gvlVendor = gvl.vendors[vendor];
            return `${parameter}${gvlVendor ? tcfStringForVendors : previousValue}`;
        });
    }
    return src;
}
/**
 * Remove the TCF parameters from a URL.
 */ function removeTcfParametersFromUrl(url) {
    const urlObj = new URL(url);
    const { searchParams } = urlObj;
    searchParams.delete("gdpr");
    urlObj.searchParams.delete("gdpr_consent");
    return urlObj.toString();
}
export { transformTcfSrc, removeTcfParametersFromUrl };
